import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"landing-card pa-0 ma-0",attrs:{"height":_vm.cardHeight,"width":_vm.cardWidth}},[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VLayout,{attrs:{"row":"","align-center":""}},[_c(VIcon,{staticClass:"ml-3 mr-2",attrs:{"color":"white"}},[_vm._v("mdi-badminton")]),_c('div',[_vm._v(_vm._s(_vm.$t("results.latestResults")))])],1),(_vm.loggedIn)?_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.showAddDialog = true}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),(_vm.results.length > 0)?_c('div',{staticClass:"custom-height mr-1 mb-2 mt-1"},[(_vm.results.length > 0)?_c('ResultsTable',{attrs:{"results":_vm.results},on:{"getResults":_vm.getResults}}):_vm._e()],1):_vm._e(),(_vm.showAddDialog === true)?_c(VOverlay,[_vm._v(" >"),_c('AddResult',{on:{"closeOverlay":function($event){return _vm.closeDialog()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }