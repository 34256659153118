import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-0, ma-0",attrs:{"color":"primary"}},[_c(VCardTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("footer.headline")))]),_c(VCardText,{staticClass:"white--text"},[_c(VContainer,{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("footer.instaLink"))+" "),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"accent","fab":"","small":"","href":_vm.instaLink}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-instagram")])],1)],1)])]),_c(VContainer,{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"white--text underline",on:{"click":function($event){_vm.showContacts = true}}},[_vm._v(" "+_vm._s(_vm.$t("general.contact"))+" ")]),_c('div',{staticClass:"d-flex flex-column align-end justify-end"},[_c('router-link',{staticClass:"white--text",attrs:{"to":"impressum"}},[_vm._v(_vm._s(_vm.$t("footer.impressumLink")))]),(_vm.$store.getters.isLoggedIn === false)?_c(VBtn,{staticClass:"white--text",attrs:{"outlined":""},on:{"click":_vm.openLoginDialog}},[_vm._v(_vm._s(_vm.$t("login.action")))]):_c(VBtn,{staticClass:"white--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.$store.commit('clearSession')}}},[_vm._v(_vm._s(_vm.$t("login.logout")))])],1)])])],1),(_vm.showLoginDialog)?_c(VOverlay,[_c('LoginDialog',{on:{"closeOverlay":function($event){_vm.showLoginDialog = false}}})],1):_vm._e(),(_vm.showContacts)?_c(VOverlay,[_c('ContactDialog',{on:{"closeOverlay":function($event){_vm.showContacts = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }