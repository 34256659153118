import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4",attrs:{"dark":_vm.$vuetify.theme.dark,"light":!_vm.$vuetify.theme.dark}},[_c(VCardTitle,{staticClass:"pa-0 ma-0"},[_vm._v(_vm._s(_vm.$t("alertCard.dialogTitle")))]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.setCard.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("alertCard.active"))+":")]),_c(VSwitch,{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c(VTextField,{attrs:{"label":_vm.$t('alertCard.title')},model:{value:(_vm.cardTitle),callback:function ($$v) {_vm.cardTitle=$$v},expression:"cardTitle"}}),_c(VTextarea,{attrs:{"label":_vm.$t('alertCard.text')},model:{value:(_vm.cardText),callback:function ($$v) {_vm.cardText=$$v},expression:"cardText"}}),_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("alertCard.color"))+":")]),_c(VColorPicker,{staticClass:"ma-1",attrs:{"dot-size":"25","swatches-max-height":"200"},model:{value:(_vm.cardColor),callback:function ($$v) {_vm.cardColor=$$v},expression:"cardColor"}}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c(VBtn,{attrs:{"disabled":!_vm.valid,"outlined":""},on:{"click":_vm.setCard}},[_vm._v(_vm._s(_vm.$t("alertCard.action")))])],1)],1),(_vm.showSnackBar)?_c('SnackBar',{attrs:{"message":_vm.ausfall.successMessage},on:{"closeSnackBar":function($event){_vm.showSnackBar = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }