import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c('img',{staticClass:"flag",attrs:{"alt":"Current language","src":_vm.currentFlag}})])]}}])},[_c(VList,_vm._l((_vm.langs),function(lang){return _c(VListItem,{key:lang.name,on:{"click":function($event){return _vm.changeLanguage(lang.language)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c('img',{staticClass:"flag",attrs:{"src":require('../../assets/flags/' + lang.flag),"alt":"Flag"}})]),_c(VListItemContent,[_vm._v(_vm._s(lang.name))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }