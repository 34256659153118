import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('div',{staticClass:"impressum_landing"}),_c('div',{staticClass:"default-headline_position"},[_c('Headline',{attrs:{"title":"imprint.headline","subtitle":"imprint.subtitle"}})],1),_c(VFlex,{class:{
      'root-Card': _vm.$vuetify.breakpoint.mdAndUp,
      'root-Card-mobile': !_vm.$vuetify.breakpoint.mdAndUp,
    }},[_c(VCard,{staticClass:"mx-auto mt-2",attrs:{"width":"100%"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("imprint.headline")))]),_c(VCardText,{staticClass:"text--primary"},[_c('h3',[_vm._v(_vm._s(_vm.$t("imprint.represented")))]),_c(VContainer,[_c('div',[_vm._v("Nancy Ulbricht, Peter Denke und Jens Vollrath")])]),_c(VContainer,[_c('div',[_vm._v(_vm._s(_vm.$t("imprint.club")))]),_c('div',[_vm._v(_vm._s(_vm.$t("imprint.address")))]),_c('div',[_vm._v(_vm._s(_vm.$t("imprint.postalCode")))]),_c('div',[_vm._v(_vm._s(_vm.$t("imprint.telephone")))])]),_c('h3',[_vm._v(_vm._s(_vm.$t("imprint.number")))]),_c(VContainer,[_c('table',{staticStyle:{"width":"100%"}},_vm._l((_vm.numbers),function(number){return _c('div',{key:number.number},[_c('tr',[_c('td',[_vm._v(_vm._s(number.number))]),_c('td',[_vm._v(_vm._s(number.text))])])])}),0),_vm._v(" "+_vm._s(_vm.$t("imprint.registered"))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t("imprint.bankInfo"))+":")]),_c(VContainer,[_c('div',[_vm._v(_vm._s(_vm.$t("imprint.bank")))]),_c('div',[_vm._v(_vm._s(_vm.$t("imprint.BIC")))]),_c('div',[_vm._v(_vm._s(_vm.$t("imprint.IBAN")))])]),_c(VDivider),_c('h3',{staticClass:"ma-1"},[_vm._v(_vm._s(_vm.$t("imprint.linkInfo")))]),_c('div',{staticClass:"ma-3"},[_vm._v(_vm._s(_vm.$t("imprint.legalInfo")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }