// English
export const en = {
    general: {
        learnMore: 'Check it out',
        close: 'Close',
        required: 'This information is required.',
        reLogin: 'Oops. Looks like your login is outdated. Please login again.',
        seeAll: 'See all',
        networkError: 'Could not connect to the server - some data won\'t load correctly!',
        noContent: 'Looks like it\'s still in progress. Come back later!',
        contact: 'Or just contact us',
        areYouSure: 'Are you sure? This action cannot be undone!'
    },
    about: {
        firstHeader: 'About us',
        subtitle: 'Who we are / We introduce ourself',
        firstChapter: 'BV 57 Niedersedlitz e.V. is a Badminton-club in the saxonian state capital Dresden, located in its eastern district. As one of the oldest badminton-clubs in Saxony, the BV 57 Niedersedlitz has its roots at BSG Chemie Niedersedlitz 1957 as section Badminton. At that time, Badminton was barely out of its infancy in germany. Today it is known as one of the three "white" sports together with tennis and squash, due to the historical tradition of wearing mostly white clothes. Responsible for establishing Badminton in Dresden are i. a. many longtime members and coaches of our club did their part in establishing Badminton also in Dresden.',
	secondChapter: 'Their joy of playing Badminton through all highs and lows in the DDR withstood even the challenges of the lesser known and mostly smiled at sport. Coaches and sportsman with a professional Badmintontraining were not in our club. Our first available two-court-gym was nowhere near the requirements of a competitive facility. Only since season 1981/82, a new five-court-sportshall could be used as a benefit for our members. Unfortunatly, you could see the shabby relation between Badminton and the GDR competitive Sport, since some of the courts weren\'t correctly drawn for the competitive purpose. Nevertheless the burden, our members enjoyed their sport and did great work.',
        thirdChapter: 'Trough aknowledgable successes, the former section could achieve a good reputation as representative of the badmintonsport. One of the reasons for this achievement was the successful implementation of the younger generation in the internal training process in the 70th. Some of those former "beginners" are active for our club even now. 1985 we even accomplished the advance in the GDR-league and could repeat this success in 1988.',
        fourthChapter: 'After the re-establishment as BV 57 Niedersedlitz e.V. in november 1990, the first team won the title of the state champion Saxony and plays since then in the upper leagues. In season 1994/95, as we achieved the 3rd place, the higher-placed squads refrained from the advance. Therefore we had the possibility to take their position in the upper "Regionalliga", but also declined, since we didn\'t have the financial resources. In case of the regular matches, the membership subscription of our at this time about 80 members were sufficient. If we were to play in the "Regionalliga", we would\'ve needed a sponsor, who would\'ve covered the additional expenditure.',
        secondHeader: 'Start-up and Achievements',
        fifthChapter: 'With about 90 members, BV 57 Niedersedlitz is one of the biggest badmintonclubs in Saxony. But not only numerical, even in terms of achievements our club can point to remarkable succusses. With 4 adult and one yunior team, we activly take part in Saxony\'s competitive scene and even further. Additionally Furthermore we cultivated friendship comparisons with Czech and Polish badminton clubs; this tradition was continued in united Germany with West German associations from Gaimersheim, Ingolstadt and Herxheim. But not only the team performances speak for themselves.',
        sixthChapter: 'Especially worth naming are terrific successes by Kerstin Radtke, Uwe Schöps and Sigwart Geihsler in the past years. They achieved several saxonian, south german and german championchip titles. Volker Hempel played badminton for more than 10 years in another traditional club,  but changed in the end to BV 57 Niedersedlitz with better sport-related perspectives. He was sevenfold GDR-Champion in single as in double disciplines.',
    },
    footer: {
        headline: 'Legal Disclosure',
        instaLink: 'Visit us on Instagram',
	impressumLink: '2024, Legal Disclosure BV57 Niedersedlitz',
    },
    imprint: {
        headline: 'Legal Disclosure',
        subtitle: 'Informations, documents and more',
        represented: 'The BV 57 Niedersedlitz e.V. will be represented by',
        club: 'BV57 Niedersedlitz',
        address: 'Post office box 53 01 09',
        postalCode: '01291 Dresden',
        telephone: 'Tel.: 0351 / 27568827',
        number: 'Our club is reachable by the following numbers',
        registered: 'Registered',
        bankInfo: 'Our bank account is',
        bank: 'Ostsächsische Sparkasse Dresden',
        IBAN: 'IBAN: DE14 8505 0300 3120 0588 81',
        BIC: 'BIC: OSDD DE81 XXX',
        linkInfo: 'Information on links to other websites',
        legalInfo: 'The contents of these pages were prepared with utmost care. Nonetheless, we cannot assume liability for the timeless accuracy and completeness of the information. <br>Our website contains links to external websites. As the contents of these third-party websites are beyond our control, we cannot accept liability for them. Responsibility for the contents of the linked pages is always held by the provider or operator of the pages.',
    },
    landing: {
        headline: 'BV57 Niedersedlitz',
        subtitle: 'Welcome to our Website!',
    },
    downloads: {
        request: 'Membership application',
        silentRequest: 'Apply abeyant membership',
        sepa: 'SEPA Lastschriftmandat for the membership fee',
        beitragordnung: 'Membership fee regulations',
        ord_ehren: 'Honorary membership regulations',
        ord_finanzen: 'Financial regulation',
        ord_business: 'Rules of procedure',
        ord_jugend: 'Youth department regulations',
        ord_recht: 'Legal regulations',
        satzung: 'Articles of association',
        spesen: 'Valid expense regulations',
        mitglied_change: 'Change membership data',
        leave: 'Apply resignation',
        kiju: 'Billing children and adolescents',
        reisekosten: 'Account for travel expenses and support',
        bvs: 'Badmintonverband Sachsen',
        all: 'All downloads',
        brandschutz: 'In case of Fire',
        hallenordnung: 'Margon Arena Rules',
        margon_unterweisung: 'Margon Arena instructions',
    },
    joinUs: {
        join: 'Join us!',
        family: 'Become a part of our Team!',
        extra: 'You always wanted to play Badminton? Simply come by for a trial training.',
        detailText: 'If you are interested, just contact us: ',
        moreInfo: 'Trainin groups, times and place'
    },
    links: {
        usefulLinks: 'Useful links',
        allDownloads: 'All downloads',
        entry: 'Entry',
        leave: 'Resignation',
        order: 'Regulations',
        members: 'For members',
    },
    navigation: {
        home: 'Home',
        aboutUs: 'About us',
        training:'Training',
        teams: 'Teams'
    },
    teams: {
        headline: 'Teams',
        subtitle: 'All of our teams and results',
        ourTeams: 'our teams',
        kids: 'Children and adolescents',
        youth: 'Junior team',
        youthText: 'Our Junior team',
        grown: 'Adults',
        firstTeam: '1st team',
        secondTeam: '2nd team',
        thirdTeam: '3rd team',
        fourthTeam: '4th team',
        firstTeamSubtitle: 'Adults in the "Bezirksliga"',
        secondTeamSubtitle: 'Adults in the "1st Bezirksklasse"',
        thirdTeamSubtitle: 'Adults in the "2nd Bezirksklasse"',
        fourthTeamSubtitle: 'Adults in the "Kreisklasse"',
        male: 'Men',
        female: 'Women',
    },
    contact: {
        contact: 'Contact',
        kids:  'Children and adolescents',
        kidsContact: 'Nico Dannowski',
        grown: 'Adults',
        grownContact: 'David Mäser',
    },
    login: {
        action: 'Login',
        username: 'Username',
        email: 'E-mail',
        password: 'Password',
        cancel: 'Cancel',
        successMessage: 'Login was successful!',
        errorMessage: 'The username or password provided is incorrect.',
        logout: 'Logout',
        dialogTitle: 'Login',
    },
    ausfall: {
        trainignsausfalle: 'Training interruptions',
        action: 'Add',
        dialogTitle: 'Add interruptions',
        date: 'Date',
        reason: 'Reason',
        partly: 'Free courts',
        successMessage: 'Training interuptions successfully added.',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDialogTitle: 'Delete entry',
    },
    results: {
        latestResults: 'Latest results',
        allResults: 'All results',
        won: 'W/L/D',
        homeTeam: 'Home',
        awayTeam: 'Guest',
        result: 'Result',
        dialogTitle: 'Edit match',
        action: 'Refresh match',
        wonOrNot: 'Result',
        linkHint: 'Link to match (turnier.de)',
        addTitle: 'Add Match',
        state: {
            win: 'Won',
            lose: 'Loss',
            draw: 'Draw',
        }
    },
    alertCard: {
        dialogTitle: 'Refresh card',
        title: 'Title',
        text: 'Text',
        color: 'Color',
        active: 'Active',
        action: 'Refresh card',
    },
    contactDialog: {
        title: 'Contacts',
        head: 'Committee',
        substitute: 'Representative',
        treasurer: 'Treasurer',
        mail: 'E-mail-adresse',
        trainer: 'Children / adolescent coach',
    },
    training: {
        clock: 'o\' Clock',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        friday: 'Friday',
        kidsCompetitive: 'Children competitive (8-12 Years)',
        kidsHobby: 'Children casual (8-12 Years)',
        youth: 'Adolescent / junior team (13-18 years)',
        grown: 'Adults',
        grownTraining: 'Training adults (no free play!)',
        trainingLocation: 'We train in the Margon arena in Dresden Seidnitz. Check out the Location on the map below. If it\'s not shown correctly, the adress is:',
        times: 'Training sessions',
        subtitle: 'Everything you need to know',
        title: 'Training',
    }
};