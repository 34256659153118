import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('div',[_c('div',{class:{
        bg_about: _vm.$vuetify.breakpoint.mdAndUp,
        bg_about_mobile: !_vm.$vuetify.breakpoint.mdAndUp,
      }}),_c('div',{staticClass:"default-headline_position"},[_c('Headline',{attrs:{"title":"about.firstHeader","subtitle":"about.subtitle"}})],1),_c(VFlex,{class:{
        'root-Card': _vm.$vuetify.breakpoint.mdAndUp,
        'root-Card-mobile': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c(VCard,{staticClass:"mx-auto mt-2"},[_c(VImg,{staticClass:"white--text align-end transformImage",attrs:{"height":"200px","alt":"Über uns","src":require('../../assets/about_header.webp')}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("about.firstHeader")))])],1),_c(VCardText,{staticClass:"text--primary"},[_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.firstChapter"))+" ")]),_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.secondChapter"))+" ")]),_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.thirdChapter"))+" ")])],1)],1),_c(VCard,{staticClass:"mx-auto"},[_c(VImg,{staticClass:"white--text align-end",attrs:{"height":"200px","alt":"Über uns","src":require('../../assets/about_header2.webp')}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("about.secondHeader")))])],1),_c(VCardText,{staticClass:"text--primary"},[_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.fourthChapter"))+" ")]),_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.fifthChapter"))+" ")]),_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t("about.sixthChapter"))+" ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }