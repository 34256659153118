import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"landing-card",attrs:{"height":_vm.cardHeight,"width":_vm.cardWidth}},[_c(VCardTitle,{staticClass:"primary white--text"},[_c(VLayout,{attrs:{"row":"","align-center":""}},[_c(VIcon,{staticClass:"ml-3 mr-2",attrs:{"color":"white"}},[_vm._v("mdi-tennis")]),_c('div',[_vm._v(_vm._s(_vm.$t("joinUs.join")))])],1)],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","flex-grow":"1"}},[_c(VCardText,{staticClass:"default-font-size mt-4"},[_vm._v(_vm._s(_vm.$t("joinUs.family")))]),_c(VCardText,{staticClass:"default-font-size my-0 py-0",on:{"click":function($event){return _vm.$router.push('/training')}}},[_c('a',[_vm._v("! "+_vm._s(_vm.$t("joinUs.moreInfo"))+" ! →")])]),_c('div',{staticClass:"mt-3",staticStyle:{"margin-top":"auto"}},[_c(VCardText,{staticClass:"default-font-size"},[_vm._v(_vm._s(_vm.$t("joinUs.detailText")))]),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("contact.grown"))+":")]),_c('div',[_c('a',{attrs:{"href":"mailto:sportwart@bv57nsdl.de"}},[_vm._v(_vm._s(_vm.$t("contact.grownContact")))])])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("contact.kids"))+":")]),_c('div',[_c('a',{attrs:{"href":"mailto:jugendwart@bv57nsdl.de"}},[_vm._v(_vm._s(_vm.$t("contact.kidsContact")))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }