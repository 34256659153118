import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"360px","width":"400px"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticClass:"card_image",attrs:{"alt":"Teams","src":require('../../assets/icons/teams_icon.webp')}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"display-4 teamText white--text",staticStyle:{}},[_vm._v(" "+_vm._s(_vm.teamShortcut)+" ")])])]),_c(VCard,{attrs:{"width":"100%","flat":""}},[_c(VCardTitle,{staticClass:"break_long_word"},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_c(VCardSubtitle,{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.$t(_vm.subtitle)))])],1)],1),_c(VDivider),(
      !_vm.players || (_vm.players.male.length === 0 && _vm.players.female.length === 0)
    )?_c('div',{staticClass:"d-flex justify-space-around"},[_vm._v(" "+_vm._s(_vm.$t("general.noContent"))+" ")]):_c(VContainer,{staticClass:"d-flex justify-space-around flex-wrap player-container",attrs:{"fluid":""}},[_c(VCard,{attrs:{"flat":""}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("teams.male")))]),_vm._l((_vm.players.male),function(player){return _c(VCardSubtitle,{key:player.id,staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-card-account-details-outline")]),_c('div',[_vm._v(_vm._s(player.name))])],1)])})],2),_c(VCard,{attrs:{"flat":""}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("teams.female")))]),_vm._l((_vm.players.female),function(player){return _c(VCardSubtitle,{key:player.id,staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-card-account-details-outline")]),_c('div',[_vm._v(_vm._s(player.name))])],1)])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }