import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      bg_teams: _vm.$vuetify.breakpoint.mdAndUp,
      bg_teams_mobile: !_vm.$vuetify.breakpoint.mdAndUp,
    }}),_c('div',{staticClass:"default-headline_position"},[_c('Headline',{attrs:{"title":"teams.headline","subtitle":"teams.subtitle"}})],1),_c(VFlex,{class:{
      'root-Card': _vm.$vuetify.breakpoint.mdAndUp,
      'root-Card-mobile': !_vm.$vuetify.breakpoint.mdAndUp,
    }},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardText,{class:{ 'pa-0': !_vm.$vuetify.breakpoint.xsAndUp }},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("teams.ourTeams")))]),_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-around"},_vm._l((_vm.teams),function(team){return _c('Team',{key:team.title,staticClass:"ma-2",attrs:{"title":team.title,"subtitle":team.subtitle,"link":team.link,"teamShortcut":team.teamShortcut,"players":team.players}})}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }