import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c('div',[_c(VList,_vm._l((_vm.links),function(link){return _c(VCard,{key:link.name,attrs:{"flat":""}},[_c(VListItem,{staticClass:"pa-0"},[_c(VListItemContent,[_c(VListItemTitle,[_c('li',[_vm._v(_vm._s(_vm.$t(link.text)))])])],1),_c(VListItemIcon,[_c(VBtn,{attrs:{"x-small":"","fab":"","color":"accent","aria-label":"Go to link"},on:{"click":function($event){link.name === 'link'
                  ? _vm.navigateToBvs()
                  : _vm.download(link.name, link.alias)}}},[_c(VIcon,[_vm._v(_vm._s(link.icon))])],1)],1)],1)],1)}),1)],1),(_vm.showAllDownloads === true)?_c(VOverlay,[_c('AllDownloads',{on:{"closeOverlay":function($event){return _vm.closeDialog()}}})],1):_vm._e(),_c(VSpacer),_c('div',{staticClass:"position-end ma-3"},[_c(VBtn,{attrs:{"color":"accent"},on:{"click":function($event){_vm.showAllDownloads = true}}},[_vm._v(_vm._s(_vm.$t("downloads.all")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }