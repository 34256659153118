import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_c('div',{staticClass:"navigation-bar d-flex align-center justify-end"},[_c(VImg,{staticClass:"mt-1 mr-auto ml-4",attrs:{"src":require('../../assets/icons/logoBV.png'),"alt":"BV57 Niedersedlitz - Badminton in Dresden","width":"150px","contain":"","lazy-src":"","max-width":"150px"}}),_vm._l((_vm.routes),function(route,i){return _c('div',{key:i},[_c(VBtn,{staticClass:"headline white--text",attrs:{"text":"","to":route.value}},[_vm._v(_vm._s(_vm.$t(route.name)))])],1)}),_c('LanguageSwitch')],2)]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_c('div',{staticClass:"navigation-bar"},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c(VImg,{staticClass:"mt-1 mr-auto ml-4",attrs:{"src":require('../../assets/icons/logoBV.png'),"width":"100px","contain":"","lazy-src":"","max-width":"100px"}}),(!_vm.$vuetify.breakpoint.mdAndUp)?_c(VMenu,{attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"large":"","icon":"","aria-label":"Open Menu"}},on),[_c(VIcon,[_vm._v("$vuetify.icons.HamburgerIcon")])],1)]}}],null,false,336786210)},[_c(VList,[_vm._l((_vm.routes),function(route,i){return _c(VListItem,{key:i,on:{"click":function () {}}},[_c(VListItemContent,[_c(VBtn,{staticClass:"headline",attrs:{"text":"","to":route.value}},[_vm._v(_vm._s(_vm.$t(route.name)))])],1)],1)}),_c(VListItem,{staticClass:"d-flex align-center justify-center"},[_c('LanguageSwitch')],1)],2)],1):_vm._e()],1)])]):_vm._e(),_c('router-view'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }