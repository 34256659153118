import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
                bg_training: _vm.$vuetify.breakpoint.mdAndUp,
                bg_training_mobile: !_vm.$vuetify.breakpoint.mdAndUp
            }}),_c('div',{staticClass:"default-headline_position"},[_c('Headline',{attrs:{"title":"training.title","subtitle":"training.subtitle"}})],1),_c(VFlex,{class:{
                'root-Card': _vm.$vuetify.breakpoint.lgAndUp,
                'root-Card-mobile': !_vm.$vuetify.breakpoint.lgAndUp
            }},[(!_vm.$vuetify.breakpoint.lgAndUp)?_c(VMenu,{staticStyle:{"z-index":"100000"},attrs:{"top":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-3 bottomRightCorner",attrs:{"fab":"","color":"primary"}},on),[_c(VIcon,[_vm._v("mdi-message")])],1)]}}],null,false,1549702623)},[_c('Contact')],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{class:{
                        desktopCard: _vm.$vuetify.breakpoint.lgAndUp,
                        mobileCard: !_vm.$vuetify.breakpoint.lgAndUp
                    }},[_c(VCard,{staticClass:"mx-auto mt-2"},[_c('div',{staticClass:"primaryColor"},[_c(VCardTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("training.times")))])],1),_c(VCardText,{staticClass:"text--primary"},_vm._l((_vm.trainings),function(slot,index){return _c(VCard,{key:index,attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t(slot.day)))]),_c(VCardText,{staticClass:"pl-0 pr-0"},[_c(VList,_vm._l((slot.times),function(timeSlot,index){return _c(VListItemGroup,{key:index,attrs:{"color":"primary"}},[_c(VListItem,{staticClass:"pr-0"},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-tennis")]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(timeSlot.slot)+" "+_vm._s(_vm.$t( "training.clock" ))+": ")])],1),_c(VListItemContent,[_c('div',[_vm._v(" "+_vm._s(_vm.$t(timeSlot.team))+" ")])])],1)],1)}),1)],1)],1)}),1)],1),_c(VCard,{staticClass:"mx-auto mt-2"},[_c('div',{staticClass:"primaryColor"},[_c(VCardTitle,{staticClass:"white--text"},[_vm._v("Trainingsort")])],1),_c(VCardText,{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("training.trainingLocation"))+" "),_c('pre',[_c('span',{staticClass:"font-weight-light"},[_vm._v("\nMargon Arena\nBodenbacher Straße 154\n01277 Dresden\n")]),_vm._v("\n            ")]),_c('div',{staticStyle:{"height":"500px","width":"100%"}},[_c('l-map',{staticStyle:{"height":"100%","width":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":_vm.mapOptions},on:{"update:center":_vm.centerUpdate,"update:zoom":_vm.zoomUpdate}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker',{attrs:{"lat-lng":_vm.withTooltip}},[_c('l-tooltip',{attrs:{"options":{
                                                permanent: true,
                                                interactive: true
                                            }}},[_c('div',{staticClass:"headline"},[_vm._v(" BV57 Halle ")])])],1)],1)],1)])],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"mt-2",staticStyle:{"width":"25%"}},[_c('Contact')],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }