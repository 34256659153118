import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,{staticClass:"sticky"},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("contact.contact")))]),_c(VCardText,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.userIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("contact.kids"))+":")]),_c(VListItemSubtitle,[_c('div',[_vm._v(_vm._s(_vm.$t("contact.kidsContact")))]),_c('a',{attrs:{"href":"mailto:jugendwart@bv57nsdl.de"}},[_vm._v("jugendwart@bv57nsdl.de")])])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.userIcon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("contact.grown"))+":")]),_c(VListItemSubtitle,[_c('div',[_vm._v(_vm._s(_vm.$t("contact.grownContact")))]),_c('a',{attrs:{"href":"mailto:sportwart@bv57nsdl.de"}},[_vm._v("sportwart@bv57nsdl.de")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }