import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VResponsive } from 'vuetify/lib/components/VResponsive';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VResponsive,{staticClass:"overflow-y-auto",attrs:{"max-height":"450"}},[_c(VDataTable,{class:{ mobile: _vm.isMobile },attrs:{"hide-default-footer":"","headers":_vm.headers,"items-per-page":_vm.results.length,"items":_vm.results,"hide-default-header":_vm.isMobile},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(!_vm.isMobile)?_c('tr',{on:{"click":function($event){return _vm.navigateTo(item.link)}}},[_c('td',{staticClass:"text-xs-right"},[_c(VIcon,{attrs:{"color":item.color}},[_vm._v("mdi-equalizer-outline")])],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.home))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.result))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.away))])]):_c('tr',{on:{"click":function($event){return _vm.navigateTo(item.link)}}},[_c('div',{class:_vm.getActiveClass(item.state)},[_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"header-item ml-4 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("results.homeTeam"))+" ")]),_c('h4',{staticClass:"header-item ml-12 pl-1 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("results.awayTeam"))+" ")])]),_c('div',{staticClass:"d-flex align-center"},[_c('td',{staticClass:"table-item"},[_vm._v(_vm._s(item.home))]),_c('td',{staticClass:"table-center-item pa-0"},[_vm._v(_vm._s(item.result))]),_c('td',{staticClass:"table-item"},[_vm._v(_vm._s(item.away))])])])])]}}])}),_c(VDivider,{staticClass:"ma-1"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }