import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4",attrs:{"dark":_vm.$vuetify.theme.dark,"light":!_vm.$vuetify.theme.dark}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("contactDialog.title")))]),_c(VList,{staticClass:"vertical-scroll",attrs:{"three-line":"","max-height":"500px"}},[_vm._l((_vm.contacts),function(contact){return [_c(VListItem,{key:contact.subtitle},[_c(VListItemAvatar,{staticClass:"ma-1 mr-3",attrs:{"size":"70"}},[_c(VImg,{attrs:{"src":contact.avatar,"contain":"","alt":contact.avatar}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(contact.title)))]),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(contact.subtitle)}}),_c(VCardText,{staticClass:"d-flex pl-0 pt-0"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("contactDialog.mail"))+":")]),_c('a',{attrs:{"href":'mailto:' + contact.email}},[_vm._v(_vm._s(contact.email))])])],1)],1)]})],2),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","width":"100%"},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("general.close")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }