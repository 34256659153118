













































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DownloadService } from "../../service/downLoadService";

// Documents
const Antrag_Mitgliedschaft = require("../../assets/documents/Antrag_Mitgliedschaft.pdf");
const Ruhende_Mitgliedschaft = require("../../assets/documents/ruhende_mitgliedschaft.pdf");
const Sepa_Mandat = require("../../assets/documents/sepa_mandat.pdf");
const beitragsordnung_2024 = require("../../assets/documents/beitragsordnung_2024.pdf");
const ordnung_ehren = require("../../assets/documents/ORDNUNG-Ehren-2001.pdf");
const ordnung_finanz = require("../../assets/documents/ORDNUNG-Finanz-1996.pdf");
const ordnung_business = require("../../assets/documents/ORDNUNG-GESCHÄFT-2003.pdf");
const ordnungjugend = require("../../assets/documents/ORDNUNG-JUGEND-2007.pdf");
const ordnung_recht = require("../../assets/documents/ORDNUNG-RECHT-2003.pdf");
const ordnung_satzung = require("../../assets/documents/ORDNUNG-Satzung.pdf");
const spensenordnung = require("../../assets/documents/spensenordnung_2024.pdf");
const change_membership = require("../../assets/documents/mitgliedsdaten_change.pdf");
const austritt = require("../../assets/documents/austritt.pdf");
const brandschutz = require("../../assets/documents/brandschutzordnung_margon.pdf");
const hallenordnung = require("../../assets/documents/hallenordnung_margon.pdf");
const abrechnung_reisekosten_unterstuetzungsbeitrag = require("../../assets/documents/Abrechnungsformular_Reisekosten_und_Unterstuetzungsbeitrag.pdf")


@Component
export default class AllDownloads extends Vue {
  public downloadService: any = new DownloadService();
  public items: any[] = [
    {
      id: "entry",
      text: this.$t("links.entry"),
      value: [
        {
          text: "downloads.sepa",
          name: Sepa_Mandat.default,
          alias: "Sepa Lastchriftmandat",
        },
        {
          text: "downloads.request",
          name: Antrag_Mitgliedschaft.default,
          alias: "Antrag auf Mitgliedschaft",
        },
      ],
    },
    {
      id: "leave",
      text: this.$t("links.leave"),
      value: [
        {
          text: "downloads.silentRequest",
          name: Ruhende_Mitgliedschaft.default,
          alias: "Ruhende Mitlgiedschaft",
        },
        {
          text: "downloads.leave",
          name: austritt.default,
          alias: "Austritt beantragen",
        },
      ],
    },
    {
      id: "order",
      text: this.$t("links.order"),
      value: [
        {
          text: "downloads.beitragordnung",
          name: beitragsordnung_2024.default,
          alias: "Beitragsordnung 2020",
        },
        {
          text: "downloads.ord_ehren",
          name: ordnung_ehren.default,
          alias: "Ehrenordnung",
        },
        {
          text: "downloads.ord_finanzen",
          name: ordnung_finanz.default,
          alias: "Finanzordnung",
        },
        {
          text: "downloads.ord_business",
          name: ordnung_business.default,
          alias: "Geschäftsordnung",
        },
        {
          text: "downloads.ord_jugend",
          name: ordnungjugend.default,
          alias: "Jugend und Kinderordnung",
        },
        {
          text: "downloads.ord_recht",
          name: ordnung_recht.default,
          alias: "Rechtsordnung",
        },
        {
          text: "downloads.satzung",
          name: ordnung_satzung.default,
          alias: "Vereinssatzung",
        },
        {
          text: "downloads.spesen",
          name: spensenordnung.default,
          alias: "Spesenordnung",
        },
        {
          text: "downloads.brandschutz",
          name: brandschutz.default,
          alias: "Brandschutzordnung",
        },
        {
          text: "downloads.hallenordnung",
          name: hallenordnung.default,
          alias: "Hallenordnung",
        }
      ],
    },
    {
      id: "members",
      text: this.$t("links.members"),
      value: [
        {
          text: "downloads.reisekosten",
          name: abrechnung_reisekosten_unterstuetzungsbeitrag.default,
          alias: "Abrechnung Reisekosten und Unterstützungsbeitrag",
        },
        {
          text: "downloads.mitglied_change",
          name: change_membership.default,
          alias: "Mitgliedsdaten ändern",
        },
      ],
    },
  ];

  public mounted() {}

  public download(filename: any, alias: any) {
    this.downloadService.downloadFile(filename, alias);
  }

  public scrollTo(id: string) {
    let scrollContainer: any = this.$refs["rootScrollContainer"];
    this.$vuetify.goTo("#" + id, {
      container: scrollContainer,
      duration: 200,
      offset: 10,
      easing: "linear",
    });
  }

  public closeOverlay() {
    this.$emit('closeOverlay');
  }
}
